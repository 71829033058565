import React from 'react';

// Components
import Layout from '../../../components/Layout';
import AboutBanner from '../../../components/AboutBanner';

// Images
import Hero from '../../../img/about_02_m_w.jpg';
import MobHero from '../../../img/about_02_m_h.jpg';
import Globe from '../../../img/globe-americas-solid.svg';
import Rocket from '../../../img/rocket-solid.svg';

// Language
import { getPackOf } from '../../../lang';
const LANG = getPackOf('zh');

const AboutBusiness = () => {
  return (
    <Layout>
      <AboutBanner pageTitle={LANG.經營理念} subTitle={LANG.經營理念副標題} bgImg={Hero} mobileBgImg={MobHero} />
      <section id="case" className="py-5">
        <div className="container">
          <div className="d-flex flex-wrap">
            {[
              {
                title: LANG.永續發展,
                content: LANG.永續發展內文,
                img: Globe,
              },
              {
                title: LANG.創新科技,
                content: LANG.創新科技內文,
                img: Rocket,
              },
            ].map((e, i) => {
              return (
                <>
                  <div className="col-12 pb-3">
                    <img
                      src={e.img}
                      alt="fa-globe-americas"
                      width="125"
                      height="125"
                      className="img-thumbnail float-right ml-3"
                    />
                    <p className="h3">{e.title}</p>
                    <p>{e.content}</p>
                  </div>
                  {i === 0 && (
                    <div id="" className="col-12 pb-3">
                      <hr className="my-4" />
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutBusiness;
